import React from "react";
import videoIcon from './library-music-icon.mp4';
import videoNotes from './music-notes-colorful-background.mp4'

const Home = () => {
  return (
    <div className="main">
      <div className="overlay"></div>
      <video src={videoNotes} autoPlay muted loop playsInline />
      <div className="content">
        <h1>Coming soon ...</h1>
        <a
         className="soundcloud-link"
          href="https://soundcloud.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="text-link">My SoundCloud</p>
        </a>
      </div>
    </div>
  )
}

export default Home